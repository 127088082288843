filterdata = { va:'', pax:'', type:'' };
animSpeed = 300;

$('#va_type').on('change', function () {
	filterdata.va = $(this).val();
	doFilter();
});

$('#pax').on('change', function () {
	filterdata.pax = $(this).val();
	doFilter();
});

$('.roomtype-cta-link').on('click', function () {
	filterdata.type = $(this).data('id');
console.log(filterdata.type);
	$('.roomtype-cta-link').removeClass('active');
	$(".roomtype-cta-link[data-id='" + filterdata.type + "']").addClass('active');
	doFilter();
});

$('.roomtype-image').on('click', function () {
	filterdata.type = $(this).data('id');

	$(".roomtype-cta-link[data-id='" + filterdata.type + "']").addClass('active');

	doFilter();
});


function doFilter() {
	
	// großen Filter ausblenden, kleinen Filter einblenden
	$( ".roomtype-filter" ).fadeOut( animSpeed, function(){
		$('.roomtype-filter__small').fadeIn(animSpeed, function(){
			$( ".roomtype-filter" ).remove();
		});
	});
	
	var checkExist = setInterval(function() {
		if (!$('.roomtype-filter').length) {

			$.ajax({
				url: "/widgets/roomfinder/getRooms/",
				async: false,
				type: 'GET',
				data: filterdata,
				success: function(res) {
						showResult(res);
				}
			});

			clearInterval(checkExist);
		}
	}, 100); // check every 100ms
 
}


function showResult(res) {
	$( ".roomtype-filter__result" ).fadeOut( animSpeed, function(){
		$('.roomtype-filter__result').html(res);
		$('.roomtype-filter__result').fadeIn(animSpeed);
	});
	
}
